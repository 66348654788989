<template>
  <div>
    <label>Kasa Oranı</label>
    <div class="btn-group flex-wrap w-100">
      <Field
        @click="setRatio"
        value="10"
        type="radio"
        class="btn-check"
        :name="inputName"
        :id="ids[0]"
        autocomplete="off"
        :class="{ 'is-invalid': errors }"
      />
      <label class="btn btn-outline-dark btn-sm" :for="ids[0]">%10</label>

      <Field
        @click="setRatio"
        value="25"
        type="radio"
        class="btn-check"
        :name="inputName"
        :id="ids[1]"
        autocomplete="off"
        :class="{ 'is-invalid': errors }"
      />
      <label class="btn btn-outline-dark btn-sm rounded-0" :for="ids[1]"
        >%25</label
      >

      <Field
        @click="setRatio"
        value="50"
        type="radio"
        class="btn-check"
        :name="inputName"
        :id="ids[2]"
        autocomplete="off"
        :class="{ 'is-invalid': errors }"
      />
      <label class="btn btn-outline-dark btn-sm rounded-0" :for="ids[2]"
        >%50</label
      >
      <Field
        @click="setRatio"
        value="75"
        type="radio"
        class="btn-check"
        :name="inputName"
        :id="ids[3]"
        autocomplete="off"
        :class="{ 'is-invalid': errors }"
      />
      <label class="btn btn-outline-dark btn-sm rounded-0" :for="ids[3]"
        >%75</label
      >

      <Field
        @click="setRatio"
        value="100"
        type="radio"
        class="btn-check"
        :name="inputName"
        :id="ids[4]"
        autocomplete="off"
        checked
        :class="{ 'is-invalid': errors }"
      />
      <label class="btn btn-outline-dark btn-sm" :for="ids[4]">%100</label>
      <ErrorMessage class="invalid-feedback" :name="inputName" />
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "RatioSelect",
  data() {
    return {
      ids: [],
    };
  },
  components: {
    Field,
    ErrorMessage,
  },
  props: ["inputName", "setRatio", "errors"],
  methods: {
    addId() {
      for (let i = 0; i < 5; i++) {
        let newId = this.inputName + i;
        this.ids.push(newId);
      }
    },
  },
  created() {
    this.addId();
  },
};
</script>

<style scoped></style>
