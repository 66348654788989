<template>
  <dashboard-layout>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
      <div class="card m-4">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <input-group
                  class="col-12 mb-3"
                  inputName="bot_name"
                  labelName="Bot Adı"
                  :errors="errors.bot_name"
                />
                <div class="col-lg-6">
                  <input-group
                    class="mb-2"
                    inputName="start_time"
                    labelName="Başlangıç Tarihi"
                    _type="datetime-local"
                    :errors="errors.start_time"
                    :_disabled="start_now"
                  />
                  <div class="form-check form-switch">
                    <label class="me-1" for="start_now">Hemen Başla</label>
                    <Field
                      type="checkbox"
                      name="start_now"
                      id="start_now"
                      class="form-check-input"
                      value="start_now"
                      @input="checkedStartNow"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <input-group
                    class="mb-2"
                    inputName="end_time"
                    labelName="Bitiş Tarihi"
                    _type="datetime-local"
                    :errors="errors.end_time"
                    :_disabled="limitless"
                  />
                  <div class="form-check form-switch">
                    <label class="me-1" for="limitless">Süresiz</label>
                    <Field
                      type="checkbox"
                      name="limitless"
                      id="limitless"
                      class="form-check-input"
                      value="limitless"
                      @input="checkedLimitless"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <ExchangesSelect
                labelName="İşlem Yapılacak Hesap"
                inputName="exchange_account"
                :errors="errors"
                :getData="getById"
              ></ExchangesSelect>
              <div class="d-flex mt-3 justify-content-evenly">
                <div>USD: {{ wallet.USD }}$</div>
                <div>BTC: {{ wallet.BTC }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mx-4 mb-4">
        <h5 class="card-header bg-dark text-light p-3">
          Macd Sinyal 1 Kural Seti
        </h5>
        <div class="card-body">
          <div class="row g-3 justify-content-evenly">
            <div class="col-md-9 card p-0 border-dark rounded-0">
              <h6 class="card-header p-3 rounded-0">İşlem Formu</h6>
              <div class="card-body row g-3 align-items-center">
                <RatioSelect
                  class="p-2 col-lg-5"
                  :setRatio="setRatio"
                  inputName="l_case_ratio"
                  :errors="errors.l_case_ratio"
                />
                <div class="d-flex flex-wrap col-lg-5">
                  <label>İşlem Tahtası</label>
                  <div class="btn-group flex-wrap w-100">
                    <Field
                      value="exchange"
                      type="radio"
                      class="btn-check"
                      name="l_transaction_table"
                      id="l_transaction_table1"
                      autocomplete="off"
                      :class="{ 'is-invalid': errors.l_transaction_table }"
                    />
                    <label
                      class="btn btn-outline-dark btn-sm"
                      for="l_transaction_table1"
                      >EXCHANGE</label
                    >
                    <Field
                      value="margin"
                      type="radio"
                      class="btn-check"
                      name="l_transaction_table"
                      id="l_transaction_table2"
                      autocomplete="off"
                      :class="{ 'is-invalid': errors.l_transaction_table }"
                    />
                    <label
                      class="btn btn-outline-dark btn-sm rounded-0"
                      for="l_transaction_table2"
                      >MARGIN</label
                    ><br />
                    <ErrorMessage
                      class="invalid-feedback"
                      name="l_transaction_table"
                    />
                  </div>
                </div>
                <input-group
                  class="col-md-4"
                  inputName="l_order_type"
                  labelName="İşlem Türü"
                  :errors="errors.l_order_type"
                  :select="true"
                  _as="select"
                  :options="[
                    { name: 'Limit', value: 'EXCHANGE LIMIT' },
                    { name: 'Scaled', value: 'SCALED' },
                  ]"
                  :_change="visibleLongScaledOrder"
                />
                <div class="col-md-6" v-if="long_scaled_order">
                  <div class="row g-1">
                    <input-group
                      class="col-lg-5"
                      inputName="l_end_point_type"
                      labelName="Tür"
                      :errors="errors.l_end_point_type"
                      :select="true"
                      _as="select"
                      :options="options"
                    />
                    <input-group
                      class="col-lg-7"
                      _type="number"
                      inputName="l_end_point"
                      labelName="Bitiş Noktası"
                      :errors="errors.l_end_point"
                    />
                  </div>
                </div>
                <input-group
                  class="col-md-2"
                  _type="number"
                  labelName="Sipariş Sayısı"
                  inputName="l_order_count"
                  :errors="errors.l_order_count"
                  v-if="long_scaled_order"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mx-4 mb-4">
        <h5 class="card-header bg-dark text-light p-3">
          Macd Sinyal 2 Kural Seti
        </h5>
        <div class="card-body">
          <div class="row g-3 justify-content-evenly">
            <div class="col-md-9 card p-0 border-dark rounded-0">
              <h6 class="card-header p-3 rounded-0">İşlem Formu</h6>
              <div class="card-body row g-3 align-items-center">
                <RatioSelect
                  class="p-2 col-lg-5"
                  inputName="s_case_ratio"
                  :setRatio="setRatio"
                  :errors="errors.s_case_ratio"
                />
                <div class="d-flex flex-wrap col-lg-5">
                  <label>İşlem Tahtası</label>
                  <div class="btn-group flex-wrap w-100">
                    <Field
                      value="exchange"
                      type="radio"
                      class="btn-check"
                      name="s_transaction_table"
                      id="s_transaction_table1"
                      autocomplete="off"
                      :class="{ 'is-invalid': errors.s_transaction_table }"
                    />
                    <label
                      class="btn btn-outline-dark btn-sm"
                      for="s_transaction_table1"
                      >EXCHANGE</label
                    >
                    <Field
                      value="margin"
                      type="radio"
                      class="btn-check"
                      name="s_transaction_table"
                      id="s_transaction_table2"
                      autocomplete="off"
                      :class="{ 'is-invalid': errors.s_transaction_table }"
                    />
                    <label
                      class="btn btn-outline-dark btn-sm rounded-0"
                      for="s_transaction_table2"
                      >MARGIN</label
                    ><br />
                    <ErrorMessage
                      class="invalid-feedback"
                      name="s_transaction_table"
                    />
                  </div>
                </div>
                <input-group
                  class="col-md-4"
                  inputName="s_order_type"
                  labelName="İşlem Türü"
                  :errors="errors.s_order_type"
                  :select="true"
                  _as="select"
                  :options="[
                    { name: 'Limit', value: 'EXCHANGE LIMIT' },
                    { name: 'Scaled', value: 'SCALED' },
                  ]"
                  :_change="visibleShortScaledOrder"
                />
                <div class="col-md-6" v-if="short_scaled_order">
                  <div class="row g-1">
                    <input-group
                      class="col-lg-5"
                      inputName="s_end_point_type"
                      labelName="Tür"
                      :errors="errors.s_end_point_type"
                      :select="true"
                      _as="select"
                      :options="options"
                    />
                    <input-group
                      class="col-lg-7"
                      _type="number"
                      inputName="s_end_point"
                      labelName="Bitiş Noktası"
                      :errors="errors.s_end_point"
                    />
                  </div>
                </div>
                <input-group
                  class="col-md-2"
                  _type="number"
                  labelName="Sipariş Sayısı"
                  inputName="s_order_count"
                  :errors="errors.s_order_count"
                  v-if="short_scaled_order"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 text-center">
        <button class="btn btn-primary w-25" type="submit" :disabled="create">
          Oluştur
          <span
            :class="[
              { 'spinner-border spinner-border-sm': create },
              { 'd-none': !create },
            ]"
          ></span>
        </button>
      </div>
    </Form>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layouts/Dashboard.layout.vue";
import ExchangesSelect from "@/components/ExchangesSelect.vue";
import RatioSelect from "@/components/RatioSelect.vue";
import InputGroup from "@/components/Dashboard/Form/InputGroup.vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import moment from "moment";
import { mapActions, mapMutations, mapState, useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    DashboardLayout,
    ExchangesSelect,
    RatioSelect,
    InputGroup,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      options: [
        { value: "percent", name: "%" },
        { value: "amount", name: "Miktar" },
      ],
      api_key: "",
      secret_key: "",
      exchange: "",
      ratio: "",
      order_type: "",
      long_scaled_order: false,
      short_scaled_order: false,
      limitless: false,
      start_now: false,
    };
  },
  methods: {
    ...mapActions("exchange", ["connectExchange", "getExchange"]),
    ...mapActions("macd_bots", ["createBot"]),
    ...mapMutations("macd_bots", ["createLoading"]),
    convertTime(date) {
      return moment(date).format("LLL");
    },
    getById(id) {
      const exchange = this.exchanges.filter((exchange) => {
        return exchange.id == id;
      });
      this.exchange = exchange[0].exchange;
      this.api_key = exchange[0].api_key;
      this.secret_key = exchange[0].secret_key;
      this.connectExchange({
        data: {
          api_key: this.api_key,
          secret_key: this.secret_key,
          exchange: this.exchange,
        },
      });
    },
    setRatio(e) {
      this.ratio = e.target.value;
    },
    visibleLongScaledOrder(order_type) {
      order_type === "SCALED"
        ? (this.long_scaled_order = true)
        : (this.long_scaled_order = false);
    },
    visibleShortScaledOrder(order_type) {
      order_type === "SCALED"
        ? (this.short_scaled_order = true)
        : (this.short_scaled_order = false);
    },
    checkedLimitless() {
      this.limitless = !this.limitless;
    },
    checkedStartNow() {
      this.start_now = !this.start_now;
    },
    onSubmit(values) {
      values["api_key"] = this.api_key;
      values["secret_key"] = this.secret_key;

      values["limitless"] === "limitless"
        ? (values["limitless"] = true)
        : (values["limitless"] = false);

      values["start_now"] === "start_now"
        ? (values["start_now"] = true)
        : (values["start_now"] = false);

      values["l_case_ratio"] = parseInt(values["l_case_ratio"]);
      values["s_case_ratio"] = parseInt(values["s_case_ratio"]);

      values["start_time"] === "" ? (values["start_time"] = null) : "";
      values["end_time"] === "" ? (values["end_time"] = null) : "";

      values["l_end_point"] === undefined ? (values["l_end_point"] = null) : "";
      values["l_end_point_type"] === undefined
        ? (values["l_end_point_type"] = null)
        : "";
      values["l_order_count"] === undefined
        ? (values["l_order_count"] = null)
        : "";

      values["s_end_point"] === undefined ? (values["s_end_point"] = null) : "";
      values["s_end_point_type"] === undefined
        ? (values["s_end_point_type"] = null)
        : "";
      values["s_order_count"] === undefined
        ? (values["s_order_count"] = null)
        : "";

      // console.log(values);
      this.createLoading();
      this.createBot({ data: values });
    },
  },
  computed: {
    ...mapState("exchange", ["exchanges", "wallet"]),
  },
  setup() {
    const store = useStore();
    const create = computed(() => store.state.macd_bots.createLoading);

    const schema = Yup.object().shape({
      bot_name: Yup.string().required("Zorunlu alan!"),
      start_time: Yup.string(),
      start_now: Yup.string(),
      end_time: Yup.string(),
      limitless: Yup.string(),
      exchange_account: Yup.string().required("Zorunlu alan!"),
      l_case_ratio: Yup.number().required("Bir oran seçmelisiniz!"),
      s_case_ratio: Yup.number().required("Bir oran seçmelisiniz!"),
      l_order_type: Yup.string().required("Zorunlu alan!"),
      s_order_type: Yup.string().required("Zorunlu alan!"),
      l_transaction_table: Yup.string().required("Bir seçim yapmalısınız!"),
      s_transaction_table: Yup.string().required("Bir seçim yapmalısınız!"),
      l_order_count: Yup.number()
        .typeError("Zorunlu alan!")
        .min(2, "En az 2 tane sipariş oluşturabilirsiniz!"),
      s_order_count: Yup.number()
        .typeError("Zorunlu alan!")
        .min(2, "En az 2 tane sipariş oluşturabilirsiniz!"),
      l_end_point: Yup.number()
        .typeError("Zorunlu alan!")
        .min(0, "0'dan küçük değer giremezsiniz!"),
      s_end_point: Yup.number()
        .typeError("Zorunlu alan!")
        .min(0, "0'dan küçük değer giremezsiniz!"),
      l_end_point_type: Yup.string(),
      s_end_point_type: Yup.string(),
    });
    return {
      schema,
      create,
    };
  },
};
</script>

<style></style>
