<template>
  <div>
    <label :for="inputName">{{ labelName }}</label>
    <Field
      v-if="!select"
      :as="_as"
      :type="_type"
      :name="inputName"
      :id="inputName"
      :class="[inputClasses, { 'is-invalid': errors }]"
      :disabled="_disabled"
      v-model="v_model"
    />
    <Field
      v-else
      :as="_as"
      :name="inputName"
      :id="inputName"
      v-model="v_model"
      @change="_change(v_model) | null"
      :class="['form-select', inputClasses, { 'is-invalid': errors }]"
    >
      <option selected disabled value="">Seçim..</option>
      <option
        v-for="_option in options"
        :key="_option.value"
        :value="_option.value"
      >
        {{ _option.name }}
      </option>
    </Field>
    <ErrorMessage class="invalid-feedback" :name="inputName" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "InputGroup",
  components: { Field, ErrorMessage },
  data() {
    return {
      v_model: "",
    };
  },
  props: {
    labelName: { type: String },
    inputName: { type: String, required: true },
    _type: {
      type: String,
      default: "text",
    },
    _as: {
      type: String,
      default: "input",
    },
    errors: { required: true },
    _disabled: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: "form-control",
    },
    select: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    _change: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style scoped>
label,
option {
  text-transform: capitalize;
}
</style>
