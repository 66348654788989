<template>
  <div>
    <label :for="inputName">{{ labelName }}</label>
    <Field
      as="select"
      :id="inputName"
      class="form-select mt-1"
      :class="{ 'is-invalid': errors.exchange }"
      :name="inputName"
      v-model="selected"
      @change="getData(selected)"
    >
      <option value="" selected disabled>Seçim..</option>
      <option
        v-for="_exchange in exchanges"
        :key="_exchange.id"
        :value="_exchange.id"
      >
        {{ _exchange.name }} / {{ _exchange.exchange }}
      </option>
    </Field>
    <ErrorMessage :name="inputName" class="invalid-feedback" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

import { mapState, mapActions } from "vuex";

export default {
  name: "ExchangeList",
  components: { Field, ErrorMessage },
  props: ["inputName", "labelName", "errors", "getData"],
  data() {
    return {
      selected: "",
    };
  },
  created() {
    this.getExchanges({ url: "/exchange/" });
  },
  methods: {
    ...mapActions("exchange", ["getExchanges"]),
  },
  computed: {
    ...mapState("exchange", ["exchanges"]),
  },
};
</script>
